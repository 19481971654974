.bar-margin {
    margin-left: 12px;
    padding: 0 4px;
    background-color: rgb(173, 173, 173);
    border-radius: 2px;
}

.number {
    width: 24px;
    text-align: center;
}

.xnumber {
    width: 24px;
    text-align: center;
}