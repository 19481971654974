@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
  min-height: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
:root { --footer-padding:0; }

@media screen and (max-width:1152px) {
  :root {
    --footer-padding: 90px;
  }
}
@media screen and (max-width:480px) {
  :root {
    --footer-padding: 100px;
  }
}
.snigle-sidev { opacity:1; }
@media screen and (max-width:1152px) {
  .snigle-sidev { opacity:0;z-index:0;display:none; }
}

body { padding-bottom: var(--footer-padding); }

.snigel-adhesive.bottom { display:flex;justify-content: center; align-items:center; }


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
